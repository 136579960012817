import React from "react";
import * as styles from './style.module.scss';

function index() {
  return (
    <div className={styles.containTitle}>
      <p data-aos="zoom-in" data-aos-delay="200">
     All in one
      </p>
      <h1 data-aos="zoom-in" data-aos-delay="100">Master WiZR Modules</h1>
    </div>
  );
}

export default index;
