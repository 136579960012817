import React from "react";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";

const Slider = ({ settings, children }) => {
    const params = {
        ...settings
    };

    return <Swiper {...params}>{children}</Swiper>;
};

export default Slider;
