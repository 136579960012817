import React, {useEffect, useMemo, useState} from "react";
import Slider from "./Swiper";
import {Image} from "../../../hooks/gatsbyImg";
import * as styles from "../../molecules/banner/banner.module.scss";
import {AiOutlineArrowLeft,AiOutlineArrowRight} from 'react-icons/ai';
import './HomeSwiper.scss';
export default function HomeSwiper() {

    let slidesNumber = (typeof window != 'undefined' && window.innerWidth > 1200) ? 2
        : ( typeof window != 'undefined' && window.innerWidth >= 768) ? 1.3 : 1.1;
    const settings = {
        effect: "coverflow",
        centeredSlides: true,
        initialSlide:1,
        slidesPerView: slidesNumber, // mobile and tablet 1.3
        spaceBetween: 300, // mobile and tablet 0
        speed:1000,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          renderPrevButton: () => (
              <>
            <button className="swiper-button-prev">
              <div name="arrow left" />
           </button>
           <button className='swiper-button-prev prev2'>
           <div name="arrow left" />
           </button>
           </>
          ),
          renderNextButton: () => (
              <>
            <button className="swiper-button-next">
              <div name="arrow right" />
           </button>
             <button className='swiper-button-next next2'>
             <div name="arrow right" />
             </button>
             </>
          ),
        coverflowEffect: {
            rotate: 0, // Slide rotate in degrees
            stretch: 100, // Stretch space between slides (in px)
            depth: 300, // Depth offset in px (slides translate in Z axis)
            modifier: 1, // Effect multipler
            slideShadows: false // Enables slides shadows
        },
    };
    const data = [
        { filename : 'Slide-item-1.png', alt : 'Home Banner 1' },
        { filename : 'Slide-item-2.png', alt : 'Home Banner 3' },
        { filename : 'Slide-item-3.png', alt : 'Home Banner 5' },
        { filename : 'Slide-item-4.png', alt : 'Home Banner 2' },
        { filename : 'Slide-item-5.png', alt : 'Home Banner 2' }

    ];

    return (
        <>
            <Slider settings={settings}>
                {data.map(item => (
                    <Image filename={item.filename} key={item.filename} className={styles.image} alt={item.alt} />
                ))}
            </Slider>
        </>
    );
}