import React from "react";
import * as styles from "./styles.module.scss";
import img from "../../../../images/screen-streamingview.png";
import StyledButton from "../../../atoms/StyledButton";
import {IoIosArrowForward} from 'react-icons/io';

function index() {
  return (
    <div className={styles.streamingView} id="streamingView">
      <div className={styles.innerContainer}>
        <div className={styles.content}>
          <div  data-aos="fade-right" data-aos-delay="200" className={styles.desc}>
            <h2>Master View</h2>
            <p>
            Easy to archive and easy to access any and all files. Master WIZR takes the way you organize, view and share presentations to a whole new level 
            </p>
            {/* <StyledButton 
            link="https://beta-studio.mwstream.com/control-panel/cover-pages"
            email={true}
            title="Try Now" icon={<IoIosArrowForward />} extraClass="whiteBtn"/> */}
          </div>
          <div  data-aos="fade-left" data-aos-delay="200"  className={styles.img}>
            <img src={img} alt='MasterWizr' />
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
