import React from 'react';
import * as styles from './styles.module.scss';
import img from '../../../../images/screens-showrooms.png';
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'gatsby';
import StyledButton from '../../../atoms/StyledButton';
import {IoIosArrowForward} from 'react-icons/io';


function index() {
    return (
        <div className={styles.showRooms} id="showRoom">
            <div className={styles.innerContainer}>
                <div className={styles.desc}>
                    <h2>Show Rooms</h2>
                    <p>Customize presentations and create follow-through materials that address exact needs and promote on point, consistent company branding.</p>
                    {/* <StyledButton title="Try Now" icon={<IoIosArrowForward />}
                    extraClass="orangeGray"
                    link="https://beta-studio.mwstream.com/control-panel/cover-pages"
                    email={true}
                    /> */}
                </div>
                <div data-aos="zoom-in" data-aos-delay="100" className={styles.img}>
                    <img src={img} />
                </div>
            </div>
        </div>
    );
}

export default index;