import React from "react";
import { useEffect } from "react";
import Footer from "../components/atoms/footer";
import Header from "../components/atoms/header";
import StreamingView from "../components/molecules/Modules/streamingView";
import ShowRooms from "../components/molecules/Modules/showRooms";
import Spaces3D from "../components/molecules/Modules/3Dspaces";
import CoverPages from "../components/molecules/Modules/coverPages";
import AOS from "aos";
import "aos/dist/aos.css";
import HeroBanner from "../components/atoms/HeroBanner";
import FreeTrial from "../components/atoms/freeTrial";
import StyledButton from "../components/atoms/StyledButton";
import Head from "../components/ogranisms/head";
import ModuleBanner from '../components/molecules/moduleBanner'
const Modules = () => {
  useEffect(() => {
    AOS.init({
      disable: "mobile",
      duration: 1500,
    });
  }, []);
  return (
    <>
      <Head
        title="MASTER WiZR Modules"
        description="Streaming View, Show Rooms. 3D Spaces. Cover Pages."
        ogTitle="Modules"
      />
      <main>
        <Header />
        <ModuleBanner />
          <Spaces3D />
          <ShowRooms />
          <StreamingView />
          <CoverPages />
          <FreeTrial />
          <Footer />
        </main>
    </>
  );
};

export default Modules;
