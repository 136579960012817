import { Link } from "gatsby";
import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./styles.module.scss";
// import img from "../../../../images/screen-coverpages.png";
import StyledButton from "../../../atoms/StyledButton";
import {IoIosArrowForward} from 'react-icons/io';
import img from '../../../../images/screen-coverpages1.png';


function index() {
  return (
    <div className={styles.spaces3d} id="coverPages">
      <div  className={styles.innerContainer}>
          <div  data-aos="fade-right" data-aos-delay="200" className={styles.img}>
              <img src={img} />
          </div>
          <div  data-aos="fade-left" data-aos-delay="200" className={styles.desc}>
              <h2>Cover Pages</h2>
              <p>
              Cover Pages are created to engage your audience in a unique and customized way. 
              </p>
             {/* <StyledButton 
             link="https://beta-studio.mwstream.com/control-panel/cover-pages"
             email={true}
             title="Try Now" extraClass="graybtn" icon={<IoIosArrowForward />}/> */}
          </div>
      </div>
    </div>
  );
}

export default index;
