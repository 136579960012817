import { Link } from "gatsby";
import React from "react";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./styles.module.scss";
import img from "../../../../images/3dspaces1.webp";
import StyledButton from "../../../atoms/StyledButton";
import {IoIosArrowForward} from 'react-icons/io';

function index() {
  return (
    <div className={styles.spaces3d} id="spaces3d">
     <div className={styles.innerContainer}>
         <div data-aos="fade-right" data-aos-delay="200" className={styles.desc}>
             <h2>3D Spaces</h2>
             <p>
             3D Spaces is all about creating environments of high-quality 3D visuals to be used in all your work. 
             </p>
            {/* <StyledButton
            link="https://beta-studio.mwstream.com/control-panel/cover-pages"
            email={true}
             title="Try Now" icon={<IoIosArrowForward />} extraClass="whiteBtn" /> */}
         </div>
         <div data-aos="fade-left" data-aos-delay="200" className={styles.img}>
             <img src={img} />
         </div>
     </div>
    </div>
  );
}

export default index;
